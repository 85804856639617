<template>
  <!--begin::plant selector row-->
  <div class="d-flex flex-row">
    <!--begin::site-->
    <div
      v-for="ems in emsList"
      :key="ems.id"
      class="overlay mr-4 mb-8 w-200px ml-4"
      @click="changeSite(ems)"
    >
      <!--begin::site image-->
      <div
        class="cursor-pointer"
        v-bind:style="[
          ems.SITE_ID != getActiveSite
            ? {
                filter: 'grayscale(100%)',
                transform: 'scale(0.9)',
                transition: 'all 1.5s ease',
              }
            : { transition: 'all 1.5s ease', transform: 'scale(1.2)' },
        ]"
      >
        <span class="text-dark ems-text_name text-xl">{{ ems.NAME }}</span>
        <img
          :src="getImage(ems.SITE_ID)"
          alt=""
          class="w-100 rounded"
          style="height: 130px"
        />
      </div>
      <!--end::site image-->
    </div>
    <!--end::site-->
  </div>
  <!--end::plant selector row-->
</template>

<script>
// import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import { Auth } from "aws-amplify";

export default {
  name: "ThePlantSelector",
  props: ["page"],
  data() {
    return {
      user: {},
      emsList: [],
    };
  },
  async mounted() {
    this.user = await Auth.currentAuthenticatedUser();
    this.emsList = this.getEmsList;
  },
  methods: {
    changeSite(item) {
      if (this.$router.currentRoute.params.siteName != item.SITE_ID)
        this.$router.push(`/${this.page}/${item.SITE_ID}`);
    },
    getImage(siteId) {
      return (
        "https://cgui-api.motivedg.com:5000/api/get_site_image?jwtToken=" +
        this.user.signInUserSession.idToken.jwtToken +
        "&siteId=" +
        siteId
      );
    },
  },
  computed: {
    ...mapGetters(["getEmsList", "getActiveSite"]),
  },
};
</script>
<style scoped>
.ems-text_name {
  position: absolute;
  left: 10px;
  font-size: 16px;
  font-weight: bold;
  top: 10px;
}
</style>
